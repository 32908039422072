var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"myModal",attrs:{"centered":"","size":"lg","header-class":"p-0","header-bg-variant":"transparent border-bottom border-bottom-2","title-class":"h2 text-white","modal-class":"modal-primary","no-close-on-backdrop":""},on:{"hidden":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('ModalHeader',{attrs:{"data-client":_vm.dataClient,"title":'GIVE BACK'},on:{"close":function($event){return _vm.hideModal()}}})]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{on:{"click":_vm.hideModal}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Give Back")])]},proxy:true}]),model:{value:(_vm.modalCenter),callback:function ($$v) {_vm.modalCenter=$$v},expression:"modalCenter"}},[_c('b-container',[_c('validation-observer',{ref:"refFormObserver"},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-start"},[_c('h5',{staticClass:"bg-primary text-white borderL"},[_vm._v(" "+_vm._s(_vm.dataClient.type == 1 ? "TO:" : "SUBJECT")+" ")]),(_vm.dataClient.type == 1)?_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('ValidationProvider',{attrs:{"name":"Pending","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"m-1 ml-1",class:{ 'border-danger rounded': errors[0] },attrs:{"id":"checkbox-group-2","options":_vm.options2,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}],null,true)})]}}],null,false,1864317571)}):_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('ValidationProvider',{attrs:{"name":"Pending","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"m-1 ml-1",class:{ 'border-danger rounded m-2': errors[0] },attrs:{"id":"checkbox-group-1","options":_vm.options,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}],null,true)})]}}])})],1)])],1),_c('b-row',{staticClass:"mt-1 fontW"},[_c('b-col',[_c('h5',{staticClass:"text-center bg-primary text-white rounded-top m-0 py-1",staticStyle:{"width":"137px"}},[_vm._v(" OBSERVATION ")])])],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"obs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"obs","placeholder":"Enter something...","rows":"3","max-rows":"6","state":errors[0] ? false : null},model:{value:(_vm.obs),callback:function ($$v) {_vm.obs=$$v},expression:"obs"}})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }