<template>
  <b-card no-body class="mb-0 border-0">
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable"
    >
      <b-table
        slot="table"
        ref="refLettersTablePending"
        class="position-relative font-small-3"
        primary-key="id"
        empty-text="No matching records found"
        select-mode="multi"
        responsive="sm"
        table-class="text-nowrap"
        sticky-header="68vh"
        small
        sort-icon-left
        show-empty
        no-provider-filtering
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :fields="fields"
        :items="myProvider"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(name_specialist)="data">
          <div>
            <small class="d-block">{{ data.item.name_specialist }}</small>
            <small>{{ data.item.date_specialist | myGlobalWithHour }}</small>
          </div>
        </template>
        <template #cell(tsend_id)="data">
          <div class="text-center">
            <b-badge
              v-if="data.item.tsend_id == 1"
              pill
              variant="primary"
              class="d-block"
              >{{ data.item.type_send }}</b-badge
            >
            <b-badge
              v-else-if="data.item.tsend_id == 2 && data.item.track_number"
              pill
              variant="success"
              class="d-block"
              >{{ data.item.type_send }}</b-badge
            >
            <b-badge pill variant="danger" class="d-block" v-else>{{
              data.item.type_send
            }}</b-badge>
          </div>
        </template>

        <template #cell(files)="data">
          <div v-if="data.item.files_merge.length">
            <div
              class="d-flex align-items-center justify-content-center merge-file"
              v-for="(item, index) in data.item.files_merge"
              :key="index"
            >
              <a href="javascript:void(0)">
                <div class="d-flex align-items-center">
                  <amg-icon
                    class="cursor-pointer bigger text-danger"
                    icon="FilePdfIcon"
                  />
                  <p
                    :class="item.state == 1 ? 'text-dark' : 'text-primary'"
                    class="m-0"
                    @click="openPdf(data.item, item)"
                  >
                    {{ item.name_file }}
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div v-else class="text-center">
            <b-button
              v-if="data.item.files.length > 0"
              variant="outline-warning"
              size="sm"
              @click.prevent="showModalFiles(data.item)"
              >Show Files</b-button
            >
            <div
              v-else
              class="d-flex justify-content-center align-items-center h-100 text-muted"
            >
              <feather-icon
                icon="SlashIcon"
                size="16"
                class="text-muted"
                style="margin-right: 3px"
              />
              <p class="m-0">NO FILES</p>
            </div>
          </div>
        </template>

        <template #cell(track_number)="data">
          <div>
            <!-- data.item.tsend_id == 2 && data.item.track_number == null -->
            <feather-icon
              icon="PlusCircleIcon"
              v-if="data.item.tsend_id == 2 && data.item.track_number == null"
              @click="openModalTrackingNumber(data.item)"
              class="cursor-pointer text-primary"
              v-b-tooltip.hover.right="'Add track number'"
              size="20"
            >
              TRACK NUMBER</feather-icon
            >
            <small
              v-else-if="data.item.tsend_id == 2 && data.item.track_number"
              >{{ data.item.track_number }}</small
            >
            <small v-else>NOT NECESSARY</small>
          </div>
        </template>
        <template #cell(tracking)="data">
          <b-button
            variant="outline"
            class="text-center widthClients text-light p-0"
            @click="openModalTracking(data.item)"
          >
            <feather-icon
              icon="ListIcon"
              size="15"
              :class="isDarkSkin ? 'text-white' : 'text-secondary'"
              class="mr-50"
            />
          </b-button>
        </template>
        <!-- actions -->
        <template #cell(actions)="data">
          <div v-if="data.item.state == 5" style="display: inline">
            <b-button
              pill
              variant="success"
              class="text-center widthClients text-light"
              @click.prevent="buttonSend(data.item, data.index)"
              :disabled="!data.item.canSend"
              size="sm"
            >
              <feather-icon
                icon="SendIcon"
                size="15"
                :class="isDarkSkin ? 'text-white mr-1' : 'text-white mr-1'"
              />Send
            </b-button>
            <b-button
              pill
              variant="danger"
              class="text-center widthClients text-light ml-1"
              @click="buttonReturn(data.item)"
              size="sm"
            >
              <feather-icon
                icon="CornerDownLeftIcon"
                size="15"
                :class="isDarkSkin ? 'text-white mr-1' : 'text-white mr-1'"
              />Return
            </b-button>
          </div>
        </template>
      </b-table>
    </filter-slot>
    <modal-tracking
      v-if="modalTracking"
      :data-tracking="dataTracking"
      @hideModal="modalTracking = false"
    />
    <modal-tracking-number
      v-if="modalTrackingNumber"
      :data-tracking="dataTracking"
      @hideModal="modalTrackingNumber = false"
      @saveModal="hideModalTrackingNumber"
    />
    <modal-return
      v-if="modalReturn"
      :data-client="dataClient"
      @hideModal="modalReturn = false"
      @updateTable="refreshTable()"
    />
    <modal-show-files
      :item.sync="itemRoundLetters"
      v-if="itemRoundLetters"
      @close="itemRoundLetters = null"
    />
  </b-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import LettersService from "@/views/correspondence/views/letters/services/letters.services";
import FilterPending from "@/views/correspondence/views/letters/components/tables/data/filtersPending";
import FieldsPending from "@/views/correspondence/views/letters/components/tables/data/fieldsPending";
import ModalTracking from "@/views/correspondence/views/letters/components/modal/ModalTracking.vue";
import ModalReturn from "@/views/correspondence/views/letters/components/modal/ModalReturn.vue";
import ModalTrackingNumber from "@/views/correspondence/views/letters/components/modal/ModalTrackingNumber.vue";
import ModalShowFiles from "@/views/correspondence/views/letters/components/modal/ModalShowFiles.vue";

export default {
  components: {
    FilterPending,
    FieldsPending,
    ModalTracking,
    ModalReturn,
    ModalTrackingNumber,
    ModalShowFiles,
  },
  data() {
    return {
      itemRoundLetters: null,
      isBusy: false,
      fields: FieldsPending.fieldsPending,
      filter: FilterPending,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      perPageOptions: [10, 25, 50, 100],
      sortBy: "",
      sortDesc: true,
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      modalTracking: false,
      modalReturn: false,
      modalTrackingNumber: false,
      dataClient: [],
      stateButton: false,
      letterItems: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      getRefreshTable: "CorrespondenceLettersStore/getRefreshTable",
    }),
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "CorrespondenceLettersStore/CHANGE_REFRESH_TABLE",
    }),
    async buttonSend(dataClient, index) {
      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const result = await LettersService.sendLetters({
            id: dataClient.id,
            obs: null,
            type: 7,
            user: this.currentUser.user_id,
          });

          if (result) {
            this.refreshTable();
          }
        } catch (error) {
          console.log("ButtonSend: ", error);
        }
      }
    },
    async buttonReturn(data) {
      this.dataClient = data;
      this.modalReturn = true;
    },
    openModalTracking(data) {
      this.dataTracking = data;
      this.modalTracking = true;
    },
    openModalTrackingNumber(data) {
      this.dataTracking = data;
      this.modalTrackingNumber = true;
    },
    hideModalTrackingNumber(data, trackingNumber) {
      this.$set(data, "track_number", trackingNumber);
      this.$set(
        data,
        "canSend",
        data.tsend_id != 2
          ? data.files.find((value) => value.state == 0) == undefined
          : !!(
              data.files.find((value) => value.state == 0) == undefined &&
              data.track_number != null
            )
      );
      this.modalTrackingNumber = false;
    },
    async myProvider(ctx) {
      let sort_By = 10;
      let sortDirection = "desc";
      try {
        const result = await LettersService.getLetterPending({
          name_text: this.filterPrincipal.model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          statusclient: this.filter[2].model,
          program: this.filter[3].model,
          idsend: this.filter[4].model ? this.filter[4].model : "",
          advisor: this.filter[5].model,
          order: sortDirection,
          orderby: sort_By,
          role_id: this.currentUser.role_id,
          user_id: this.currentUser.user_id,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
        });
        this.letterItems = result.data;
        this.letterItems.forEach(async (letter) => {
          if (letter.files_merge.length > 0) {
            this.$set(
              letter,
              "canSend",
              letter.tsend_id != 2
                ? letter.files_merge.find((value) => value.state == 0) ==
                    undefined
                : !!(
                    letter.files_merge.find((value) => value.state == 0) ==
                      undefined && letter.track_number != null
                  )
            );
          }
          if (letter.files.length > 0 && letter.files_merge.length === 0) {
            this.$set(
              letter,
              "canSend",
              letter.tsend_id != 2
                ? letter.files.find((value) => value.state == 0) == undefined
                : !!(
                    letter.files.find((value) => value.state == 0) ==
                      undefined && letter.track_number != null
                  )
            );
          }
        });
        this.startPage = result.from;
        this.toPage = result.to;
        this.totalRows = result.total;
        this.paginate.currentPage = result.current_page;
        this.paginate.perPage = result.per_page;
        return this.letterItems;
      } catch (error) {
        console.log("Somtehing went wrong myProvider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    showModalFiles(item) {
      this.itemRoundLetters = item;
    },
    async openPdf(dataClient, dataFile) {
      try {
        await LettersService.refreshStateFileMerge({
          id: dataFile.id,
        });

        window.open(dataFile.newroute, "_blank");

        this.$set(dataFile, "state", 1);
        this.$set(
          dataClient,
          "canSend",
          dataClient.tsend_id != 2
            ? dataClient.files_merge.find((value) => value.state == 0) ==
                undefined
            : !!(
                dataClient.files_merge.find((value) => value.state == 0) ==
                  undefined && dataClient.track_number != null
              )
        );
      } catch (ex) {}
    },
    async refreshTable() {
      this.$refs["refLettersTablePending"].refresh();
    },
  },
  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs["refLettersTablePending"].refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
    itemRoundLetters: {
      handler(value) {
        if (value) {
          const totalViews = value.files.filter((x) => x.state === 0);
          if (totalViews.length === 0) {
            this.letterItems.forEach((letter) => {
              if (letter.id == value.id) {
                this.$set(letter, "files", value.files);
                this.$set(
                  letter,
                  "canSend",
                  letter.tsend_id != 2
                    ? letter.files.find((value) => value.state == 0) ==
                        undefined
                    : !!(
                        letter.files.find((value) => value.state == 0) ==
                          undefined && letter.track_number != null
                      )
                );
              }
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.merge-file {
  padding: 5px;
}
.merge-file p {
  padding-left: 5px;
}
.w-15 {
  width: 15px !important;
}
</style>
