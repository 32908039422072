<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalCenter"
      centered
      size="lg"
      header-class="p-0"
      header-bg-variant="transparent border-bottom border-bottom-2"
      title-class="h2 text-white"
      modal-class="modal-primary"
      no-close-on-backdrop
      @hidden="hideModal"
    >
      <template v-slot:modal-header>
        <ModalHeader
          :data-client="dataClient"
          :title="'GIVE BACK'"
          @close="hideModal()"
        />
      </template>
      <b-container>
        <validation-observer ref="refFormObserver">
          <b-row class="mt-1">
            <b-col>
              <div class="d-flex justify-content-start">
                <h5 class="bg-primary text-white borderL">
                  {{ dataClient.type == 1 ? "TO:" : "SUBJECT" }}
                </h5>

                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  v-if="dataClient.type == 1"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Pending"
                    rules="required"
                  >
                    <b-form-radio-group
                      id="checkbox-group-2"
                      v-model="type"
                      :options="options2"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-1"
                      class="m-1 ml-1"
                      :class="{ 'border-danger rounded': errors[0] }"
                    ></b-form-radio-group>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group v-slot="{ ariaDescribedby }" v-else>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Pending"
                    rules="required"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="selected"
                      :options="options"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-1"
                      class="m-1 ml-1"
                      :class="{ 'border-danger rounded m-2': errors[0] }"
                    ></b-form-checkbox-group>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1 fontW">
            <b-col>
              <h5
                class="text-center bg-primary text-white rounded-top m-0 py-1"
                style="width: 137px"
              >
                OBSERVATION
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                name="obs"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-textarea
                  id="obs"
                  v-model="obs"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  :state="errors[0] ? false : null"
                ></b-form-textarea>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
      <template #modal-footer>
        <b-button @click="hideModal">Cancel</b-button>
        <b-button variant="primary" @click="onSubmit">Give Back</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalHeader from "@/views/correspondence/views/letters/components/modal/ModalHeader.vue";
import LettersService from "@/views/correspondence/views/letters/services/letters.services";
import { ValidationObserver } from "vee-validate";
export default {
  components: { ModalHeader, ValidationObserver },
  props: {
    dataClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      modalCenter: true,
      obs: null,
      options: [
        { text: "ID", value: "ID" },
        { text: "UB", value: "UB" },
        // { text: "EQR", value: "EQR" },
        // { text: "EXR", value: "EXR" },
        // { text: "TUR", value: "TUR" },
        // { text: "EQ", value: "EQ" },
        // { text: "EX", value: "EX" },
        // { text: "TU", value: "TU" },
        // { text: "SSN", value: "SSN" },
      ],
      type: null,
      options2: [
        { text: "SPECIALIST", value: 4 },
        { text: "ADVISOR", value: 3 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    observation() {
      if (this.selected.length > 0) {
        let elent = this.selected.join(" | ");
        let obser = elent + " <br/> " + this.obs;
        return obser;
      } else return this.obs;
    },
    async onSubmit() {
      if (await this.$refs.refFormObserver.validate()) {
        let confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          try {
            await LettersService.returnLetters({
              id: this.dataClient.id,
              itequ: 0,
              itexp: 0,
              ittra: 0,
              obs: this.observation(),
              type: this.dataClient.type == 1 ? this.type : 10,
              user: this.currentUser.user_id,
              module: this.dataClient.module_id,
              subject: this.selected,
              observation: this.obs,
            });
            this.$emit("updateTable");
            this.hideModal();
            this.removePreloader();
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "The letters has been update."
            );
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
  },
};
</script>
<style scoped lang="scss">
.borderL {
  width: 19%;
  padding-top: 17px;
  padding-left: 40px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
