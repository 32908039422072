const fieldsPending = [
    {
        key: "name_client",
        label: "Client Name",
    },
    {
        key: "account",

    },
    {
        key: "program_name",
        label: "Program",
    },
    {
        key: "name_specialist",
        label: "Request By",
    },
    {
        key: "tsend_id",
        label: "Type",
        thClass: "text-center",

    },
    {
        key: "files",
        thClass: "text-center",
    },
    {
        key: "track_number",
        label: "Track Number",
        thClass: "text-center",
        tdClass: "text-center",
    },
    {
        key: "tracking",
        thClass: "text-center",
        tdClass: "text-center",
    },

    {
        key: "actions",
        thClass: "text-center",
        thStyle: "width: 250px"


    },
];

export default {
    fieldsPending,
};
