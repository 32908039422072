<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="OTHER FILES"
    size="sm"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div v-if="dataClient">
      <div v-for="(element, index) in dataClient.files" :key="index">
        <a
          :class="element.state == 0 ? 'float-left' : 'opa float-left'"
          style="width: 170px"
          target="_blank"
          @click="openPdf(element)"
          v-if="
            element.name_file == 'TUR.pdf' ||
            element.name_file == 'EXR.pdf' ||
            element.name_file == 'EQR.pdf'
          "
        >
          <div class="d-flex align-items-center">
            <amg-icon
              class="cursor-pointer mr-1 bigger text-danger"
              icon="FilePdfIcon"
            />
            <p
              :class="element.state == 1 ? 'text-dark' : 'text-primary'"
              class="m-0 mb-1 mt-1"
            >
              {{ element.name_file }}
            </p>
          </div>
        </a>
        <a
          :class="element.state == 0 ? 'float-left' : 'opa float-left'"
          style="width: 170px"
          v-else
          @click="refreshStateFile(element)"
        >
          <div class="d-flex align-items-center">
            <amg-icon
              class="cursor-pointer mr-1 bigger text-danger"
              icon="FilePdfIcon"
            />
            <p
              :class="element.state == 1 ? 'text-dark' : 'text-primary'"
              class="m-0 mb-1 mt-1 text-primary"
            >
              {{ element.name_file }}
            </p>
          </div>
        </a>
      </div>
    </div>
  </b-modal>
</template>
<script>
import LettersService from "@/views/correspondence/views/letters/services/letters.services";
export default {
  name: "ModalShowFiles",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataClient: null,
    };
  },
  watch: {
    item: {
      handler(value) {
        this.dataClient = { ...value };
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.toggleModal("modalName");
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async openPdf(datafile) {
      const result = await LettersService.getCorrespondenceLettersPdf({
        round: this.dataClient.id,
        bureau:
          datafile.name_file == "TUR.pdf"
            ? 1
            : datafile.name_file == "EXR.pdf"
            ? 2
            : 3,
        idfile: datafile.id,
        namefile: datafile.name_file,
      });
      if (result) {
        this.forceFileDownload(result, "document.pdf", "blank");
        this.$set(datafile, "state", 1);
        this.$set(
          this.dataClient,
          "canSend",
          this.dataClient.tsend_id != 2
            ? this.dataClient.files.find((value) => value.state == 0) ==
                undefined
            : !!(
                this.dataClient.files.find((value) => value.state == 0) ==
                  undefined && this.dataClient.track_number != null
              )
        );
        this.$emit("update:item", this.dataClient);
      }
    },

    async refreshStateFile(dataFile) {
      try {
        await LettersService.refreshStateFile({
          id: dataFile.id,
        });
        window.open(dataFile.newroute, "_blank");
        this.$set(dataFile, "state", 1);
        this.$set(
          this.dataClient,
          "canSend",
          this.dataClient.tsend_id != 2
            ? this.dataClient.files.find((value) => value.state == 0) == undefined
            : !!(
                this.dataClient.files.find((value) => value.state == 0) ==
                  undefined && this.dataClient.track_number != null
              )
        );

        this.$emit("update:item", this.dataClient);

      } catch (error) {}
    },
  },
};
</script>

<style>
.opa {
  opacity: 0.5;
}
</style>