<template>
  <div>
    <b-modal
      v-model="modalTrackingNumber"
      centered
      size="lg"
      title="TRACK NUMBER"
      title-class="h2 text-white"
      modal-class="modal-primary"
      @hidden="hideModal"
      @ok.prevent="setTrackingNumber()"
    >
      <b-container>
        <validation-observer ref="refFormObserver">
          <validation-provider
            name="obs"
            rules="required|required-tracking"
            v-slot="{ errors }"
          >
            <b-form-group label="Insert Track Number">
              <b-form-input
                v-model="trackingNumber"
                v-mask="'XXXX-XXXX-XXXX-XXXX'"
                :state="errors[0] ? false : null"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import LettersService from "../../services/letters.services.js";
export default {
  props: {
    dataTracking: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      modalTrackingNumber: true,
      trackingNumber: "",
    };
  },
  methods: {
    saveModal() {
      this.modalTrackingNumber = false;
      this.$emit("saveModal", this.dataTracking, this.trackingNumber.replace(/-/g, ''));
    },
    hideModal() {
      this.modalTrackingNumber = false;
      this.$emit("hideModal");
    },
    async setTrackingNumber() {
      try {
        const result = await this.$refs.refFormObserver.validate();
        if(result){
            this.addPreloader();
            await LettersService.setTrackingNumber({
              id_round: this.dataTracking.id,
              track_number: this.trackingNumber.replace(/-/g, ''),
            });
            this.showSuccessSwal("Tracking Number Saved");
            this.saveModal();
            this.removePreloader();
        }
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
  },
};
</script>
